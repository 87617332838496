<template>
	<div class="container">
		<div class="overview">
			<div class="text">
				<div class="line"></div>
				<p class="title">超低能耗核酸采样室</p>
				<p class="subTitle">医学观察、居家隔离小门神！</p>
			</div>
			
			<div class="img-text">
				<img src="../../assets/imgs/cha1.png" alt="">
				<div class="right">
					<p class="name">产品概述</p>
					<p class="content">小门神超低能耗核酸采样室由河北天乾地坤科技有限公司自主研发生产，采 用一体化集成设计，舱体采用高强度金属材质，加装保温隔离材料，适用高 温低寒环境工作，内部具有新风系统、冷暖空调、多重过滤消杀系统、紫外 线定时消杀、隐藏式垃圾和试管存储区、地图定位系统等设计，外观简洁美 观，内部工作环境温度舒适，可快速灵活部署，适用于疾控中心、医院、社 区卫生服务中心、商超、火车站、机场、海关及第三方检测机构等生物样品 采样场景。</p>
				</div>
			</div>
		</div>
		
		<div class="physical">
			<div class="text">
				<div class="line"></div>
				<p class="title">实物效果图</p>
			</div>
			
			<div class="img-text">
				<div class="left">
					<img src="../../assets/imgs/cha2.png" alt="">
					<p>门神超低能耗核酸采样室单工位</p>
				</div>
				<div class="right">
					<img src="../../assets/imgs/cha3.png" alt="">
					<p>小门神超低能耗核酸采样室双工位</p>
				</div>
			</div>
		</div>
		
		<div style="background: #F4F8FF">
			<div class="advantage">
				<div class="text">
					<div class="line"></div>
					<p class="title">功能优势</p>
				</div>
				<div class="content">
					<p>1.智能化程度高，身份证、扫码；一体化快速录入</p>
					<p>2.正压系统，病毒不会进入，能提供稳定独立的安全环境系统</p>
					<p>3.核酸采样更加快速便捷测温、扫码、采样、打印高效便捷</p>
					<p>4.环境系统具有自净化功能，能够为采样人员提供稳定安全的环境保障</p>
					<p>5.环境舒适、温度恒定、采样人员工作效能大幅提升</p>
					<p>6.满足应急医用模块化隔离单元</p>
					<p>7.通用技术要求，环境适应性强，性能稳定可靠</p>
				</div>
			</div>
		</div>
		
		<div class="characteristics">
			<img src="../../assets/imgs/cha4.png" alt="">
		</div>
		
		<div class="case">
			<p class="title">核酸采样室案例</p>
			<img src="../../assets/imgs/cha5.png" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		
	}
</script>

<style scoped>
	
.line {
	width: 61px;
	height: 7px;
	background: #184BAB;
}

.title {
	height: 36px;
	font-size: 36px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin-top: 14px;
}

.subTitle {
	height: 16px;
	font-size: 16px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
}
.overview {
	width: 1200px;
	margin: 0 auto;
	padding: 126px 117px 0;
	box-sizing: border-box;
}
.overview .text {
	position: relative;
}

.overview .text .subTitle {
	margin-top: 8px;
}

.overview .img-text {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 90px;
	position: relative;
	left: 100px;
}

.overview .img-text .right {
	margin-left: 74px;
}

.overview .img-text .right .name {
	height: 32px;
	font-size: 32px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}

.overview .img-text .right .content {
	width: 474px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	line-height: 22px;
	margin-top: 37px;
}

.physical {
	width: 1200px;
	margin: 0 auto;
	padding:96px 116px 54px;
	box-sizing: border-box;
}

.physical .img-text {
	margin-top: 54px;
	position: relative;
	left: 32px;
	display: flex;
	align-items: flex-end;
}

.physical .img-text p {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	margin-top: 46px;
	text-align: left;
}

.physical .img-text .left {
	margin-right: 163px;
}

.advantage {
	width: 1200px;
	margin: 0 auto;
	padding: 58px 116px 129px;
	box-sizing: border-box;
}

.advantage .content {
	margin-top: 54px;
	
}
.advantage .content p {
	width: 618px;
	font-size: 20px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	line-height: 62px;
}

.characteristics {
	width: 1200px;
	text-align: center;
	margin: 60px auto 0;
}

.case {
	width: 1200px;
	margin: 147px auto 0;
	padding-left: 109px;
	box-sizing: border-box;
}

.case img {
	margin-top: 45px;
	margin-bottom: 127px;
}
</style>